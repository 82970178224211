import { PerspectiveCamera } from "three";

export function createCamera() {
    let camera = new PerspectiveCamera(
        50, // Field of view
        1, // Aspect Ratio
        0.1, // near clipping plane
        500, // far clipping plane
    )

    camera.position.set(0, 0, 0)
        
    return camera
}

