import { Color, Scene } from 'three'

export function createScene() {
    const scene = new Scene()

    // scene.background = new Color('black')
    // const color = 0xFFFFFF;  // white
    // const near = 50;
    // const far = 52;
    // scene.fog = new THREE.Fog(color, near, far);

    return scene;
}

