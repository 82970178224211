import { createCamera } from "./components/camera.js"
// import { createCube } from "./components/cube.js"
import { createTorusLogo } from "./components/torus_logo.js"
import { createLights } from "./components/lights.js"
import { createScene } from "./components/scene.js"
import { createGround } from "./components/ground.js"

import { createRenderer } from "./systems/renderer.js"
import { createResizer, Resizer } from "./systems/Resizer.js"
import { Loop } from "./systems/Loop.js"
import { GUI } from "dat.gui"

import { Box3, Vector3 } from 'three'

import { gsap } from "gsap"
import { GSDevTools } from "gsap/all"

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const pagePositions = {
    enter: 'centerf',
    player: 'centerc',
    story: 'right'
}

let camera
let scene
let renderer
let loop
let center
let size
let torusLogo
let duration = 1
let tl
let clientWidth
let rightX = 5
let rightRotY = -1.7
let zoomZ = 1
let currentPage = ""
let triggers = $('.gsap-trigger')

export class World {
    constructor(container) {
        camera = createCamera()
        scene = createScene()
        renderer = createRenderer()
        loop = new Loop(camera, scene, renderer)
        container.append(renderer.domElement)
        tl = gsap.timeline()
        clientWidth = container.clientWidth
        this.setTorusMoves(clientWidth)

        torusLogo = createTorusLogo()
        const lights = createLights()
        const ground = createGround()

        const resizer = new Resizer(container, camera, renderer)
        const controls = new OrbitControls (camera, renderer.domElement);
        loop.updatables.push(torusLogo)

        ground.rotateX(30)
        scene.add(torusLogo)
    
        var aabb = new Box3().setFromObject( torusLogo )
		center = aabb.getCenter( new Vector3() )
		size = aabb.getSize( new Vector3() )

        $(window).on('resize', () => {
            this.resizeTorus(container, camera)
        })

    }

    async init() {
        // gsap.to('#pageContainer', {
        //     opacity: 0
        // })
    }

    render() {
        renderer.render(scene, camera)
    }

    start() {
        loop.start()
    }

    stop() {
        loop.stop()
    }

    setCurrentPage(page, position, noDelay) {
        if (this.currentPage == page) {
            return
        }
        console.log('load', page)
        position = position || pagePositions[page]
        let pageEl = $("#pageContainer")
        let cssEl = $('#pageCSS')
        let jsEL = $('#pageJS')
        let pageURL = '/pages/' + page + '/' + page + '.html'
        let cssURL = '/pages/' + page + '/' + page + '.css';
        let jsURL = '/pages/' + page + '/' + page + '.js';
        let iframeWidth = position === 'right' ? '65%' : '100%'
        let iframeMargin = position === 'right' ? '25px' : 'unset'
        
        history.pushState(null, null, window.location.origin + '#page:' + page)
        triggers.removeClass('selected')
        $('[data-gsapto=' + page + ']').addClass('selected')
        this.currentPage = page

        tl.seek(tl.endTime());
        
        tl.to('#pageContainer', {
            opacity: 0,
            onComplete: function() {
                $(pageEl).load(pageURL)
                $(pageJS).attr('src', jsURL)
                $(pageCSS).attr('href', cssURL)
                gsap.set('#pageContainer', {
                    height: '100%'
                })

            }
        }, 2)

        tl.set('#pageContainer', {
            display: 'none',
        })

        tl.set('#iframeContainer', {
            width: iframeWidth,
            marginLeft: iframeMargin
        })
        
        switch(position || 'centerf') {
            case 'centerf':
                $('#menuContainer').hide()
                torusLogo.changeTexture(1)
                tl.to( camera.position, {
                    duration: 1,
                    x: 0, 
                    y: 0,
                    z: 6
                } )
                .to( torusLogo.position, {
                    duration: duration,
                    x: 0,
                    y: 0,
                    z: 0
                }, "<")
                .to( torusLogo.rotation, {
                    x: 0,
                    y: 0,
                    z: 0
                }, "<")
            break

            case 'centerc':
                $('#menuContainer').show()
                torusLogo.changeTexture(0)
                tl.to( camera.position, {
                    duration: 2,
                    x: 0, 
                    y: 0,
                    z: zoomZ
                }, "<").to( torusLogo.position, {
                    duration: duration,
                    x: 0,
                    y: 0,
                    z: 0
                }, "<").to( torusLogo.rotation, {
                    x: 0,
                    y: 0,
                    z: 0
                }, "<")

            break

            case 'right':
                $('#menuContainer').show()
                torusLogo.changeTexture(2)
                tl.to( torusLogo.position, {
                    duration: 2,
                    x: rightX,
                    y: 0,
                    z: -3
                })
                tl.to( torusLogo.rotation, {
                    duration: 2,
                    x: 0,
                    y: rightRotY,
                    z: 0
                }, "-=2")
            break
        }
        
        // show current page
        tl.to('#pageContainer', {
            display: 'block',
            opacity: 1,
            duration: 1,
            onComplete: function() {
                // let newHeight = page === 'story' ? $('#pageContainer').contents().height() : '100%'
                if (page === 'story') {
                    gsap.set('#pageContainer', {
                        height: $('#pageContainer').contents().height()
                    })
                }
            }
        }, noDelay ? "-=1" : null)
        tl.play()
    }

    resizeTorus(container, camera) {
        this.setTorusMoves(container.clientWidth)
    }

    setTorusMoves(width) {
        if (width < 767) {
            rightX = 3.5
            rightRotY = -1.5
            zoomZ = 2.25
        }
        else {
            rightX = 6
            rightRotY = -1.7
            zoomZ = 1
        }
    }

}
