import { PlaneGeometry, Mesh, TextureLoader, MeshLambertMaterial } from "three";

export function createGround() {
    const spec = {
        color: "purple"
    }
    const geometry = new PlaneGeometry(100, 100)
    const ground = new Mesh(geometry, createMaterial())
    ground.position.y = -5

    ground.tick = (delta) => {
        
    }
    
    return ground
}

function createMaterial() {
    const textureLoader = new TextureLoader()
    const texture = textureLoader.load('/textures/ground_0028_color_1k.jpg')
    
    const material = new MeshLambertMaterial({
        map: texture,
        emmissive: 0xffffff
    })

    return material
}