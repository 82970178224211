
import { TorusGeometry, Mesh, ShaderMaterial, TextureLoader, FrontSide, Clock } from "three"
import { gsap } from "gsap"

export function createTorusLogo() {
    const geometry = new TorusGeometry(3, 2, 50, 50)
    const textures = [
        new TextureLoader().load('/textures/rft-yellow.jpg'),
        new TextureLoader().load('/textures/psych-bg-4.jpeg'),
        new TextureLoader().load('/textures/pack_11_25_freebie.jpeg'),
        new TextureLoader().load('/textures/psych-bg-6.jpeg'),
    ]
    const clock = new Clock()
    let currentBlend = 0
    let selectedTexture = 0
    let currentTextures = [
        textures[0],
        textures[2]
    ]

    const material = new ShaderMaterial({
        vertexShader: /* glsl */ `
            varying vec2 vUv;
            uniform float uTime;

            void main() {
                vUv = uv;

                vec3 transformed = position;

                gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
            }
        `,
        fragmentShader: /* glsl */ `
            varying vec2 vUv;
            uniform float uTime;
            uniform float blendAmt;
            uniform sampler2D uTexture1;
            uniform sampler2D uTexture2;

            void main() {
                float time = uTime * .3;
                float blend = blendAmt;

                vec2 uv = vUv;
                vec2 repeat = vec2(6.0, 6.0);
                uv.x += sin(uv.y) * .01;
                uv = fract(uv * repeat + vec2(0.0, time));
                
                vec4 tex1 = texture2D(uTexture1, uv);
                vec4 tex2 = texture2D(uTexture2, uv);
                tex2 = tex2.a > 0.5 ? tex2 : vec4(0, 0, 0, 1);

                gl_FragColor = mix( tex1, tex2, blend );
            }
        `,
        uniforms: {
            uTime: { value: 0 },
            blendAmt: { value: 0.0 },
            uTexture1: { value: currentTextures[0] },
            uTexture2: { value: currentTextures[1] }
        },
        transparent: false,
        side: FrontSide
    })

    const torus = new Mesh(geometry, material)

    torus.changeMaterial = blend => {
        gsap.to(material.uniforms.blendAmt, {
            value: blend, 
            duration: 5
        })
        currentBlend = blend
    }

    torus.changeTexture = tex => {
        let newBlend = currentBlend === 0 ? 1 : 0
        currentTextures[newBlend] = textures[tex]
        material.uniforms.uTexture1.value = currentTextures[0]
        material.uniforms.uTexture2.value = textures[1]
        currentBlend = newBlend
        gsap.to(material.uniforms.blendAmt, {
            value: newBlend, 
            duration: 5
        })
    }

    torus.tick = (delta) => {
        material.uniforms.uTime.value = clock.getElapsedTime()
        torus.rotation.z -= .002
    }
    
    return torus
}
