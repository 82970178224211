import { THREE } from 'three'
import { MathUtils } from 'three';

const setSize = (container, camera, renderer) => {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
}

export class Resizer {
    
    constructor(container, camera, renderer) {
        setSize(container, camera, renderer)

        window.addEventListener('resize', () => {
            setSize(container, camera, renderer)
            this.onResize(container, camera, renderer)
        })
    }

    onResize(container, camera, renderer) {
        
        var vFOV = MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
        var height = 2 * Math.tan( vFOV / 2 ) * 4; // visible height
        var width = height * camera.aspect;           // visible width

        console.log('resize', width)
    }
}